var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fluxo-recursos"},[_c('Nav'),_vm._v(" "),_c('Icon'),_vm._v(" "),_c('div',{staticClass:"grid-box"},[_c('Breadcrumb',{attrs:{"levels":_vm.breadcrumbLevels}}),_vm._v(" "),_c('div',{staticClass:"main-block"},[_c('div',{staticClass:"fr-menu"},[(
            _vm.hasPermission(
              [_vm.permissions.ConciliationRH],
              _vm.$route.query.entityId
            ) &&
            (_vm.entity.type == _vm.entityTypeEnum.AUC ||
              _vm.entity.type == _vm.entityTypeEnum.Company)
          )?_c('div',{staticClass:"header-menu",class:_vm.pageOptions.CurrentPage == -1 ? 'active' : '',staticStyle:{"position":"relative"}},[_c('router-link',{attrs:{"to":{ name: _vm.routes.app.HrConciliation }}},[_c('h3',[_c('faIcon',{attrs:{"type":"fal","name":"users"}}),_vm._v("Conciliação RH")],1)])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"header-menu",class:_vm.pageOptions.CurrentPage == _vm.cashFlowPages.StatusZero
              ? 'active'
              : '',staticStyle:{"position":"relative"}},[_c('a',{on:{"click":function($event){return _vm.geralReportByEntity(_vm.$route.query.entityId)}}},[_c('h3',[_c('faIcon',{attrs:{"type":"fal","name":"users"}}),_vm._v("Relatório Completo")],1)]),_vm._v(" "),(_vm.$route.query.entityId != 220 && _vm.$route.query.entityId != 214)?_c('InputSelect',{attrs:{"items":[
              { id: null, name: 'Todos'},
              { id: 2023, name: 2023 },
              { id: 2022, name: 2022 },
              { id: 2021, name: 2021 },
              { id: 2020, name: 2020 },
              { id: 2019, name: 2019 } ],"textLabel":"Ano","helpLabel":"Ano filtrado no relatório","valueAttribute":"id","textAttribute":"name"},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"menu-list"},[_c('h3',{staticClass:"ml-3 fw-600"},[_c('faIcon',{staticClass:"mr-2 text-gray",attrs:{"type":"fal","size":"20px","name":"search-dollar"}}),_vm._v("Fluxo financeiro\n          ")],1),_vm._v(" "),_c('ul',{staticClass:"mt-3"},_vm._l((_vm.listMenusWithPermissions),function(item,index){return _c('li',{key:index},[(_vm.hasAccess(item.el.entityType))?_c('router-link',{attrs:{"to":{
                  name: item.el.route,
                  params: { order: item.index, name: item.el.name },
                }}},[_c('em',[_vm._v("#"+_vm._s(item.index))]),_vm._v("\n                "+_vm._s(item.el.name)+"\n              ")]):_vm._e()],1)}),0)])]),_vm._v(" "),_c('div',{staticClass:"boxapp"},[_c('router-view',{attrs:{"pageOptions":_vm.pageOptions,"entity":_vm.entity},on:{"changeLevels":_vm.changeLevels,"pageActive":_vm.changePageActive}})],1)])],1)],1)}
var staticRenderFns = []
export { render, staticRenderFns }