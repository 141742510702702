<template>
  <div class="fluxo-recursos">
    <Nav />
    <Icon />
    <div class="grid-box">
      <Breadcrumb :levels="breadcrumbLevels" />
      <div class="main-block">
        <div class="fr-menu">
          <!-- header menu ################### -->
          <div
            class="header-menu"
            :class="pageOptions.CurrentPage == -1 ? 'active' : ''"
            style="position: relative"
            v-if="
              hasPermission(
                [permissions.ConciliationRH],
                $route.query.entityId
              ) &&
              (entity.type == entityTypeEnum.AUC ||
                entity.type == entityTypeEnum.Company)
            "
          >
            <!-- active para conciliação -->
            <!-- toggle .active  -->
            <router-link :to="{ name: routes.app.HrConciliation }">
              <h3><faIcon type="fal" name="users" />Conciliação RH</h3>
            </router-link>
          </div>
          <div
            class="header-menu"
            :class="
              pageOptions.CurrentPage == cashFlowPages.StatusZero
                ? 'active'
                : ''
            "
            style="position: relative"
          >
            <!-- active para conciliação -->
            <!-- toggle .active  -->
            <a @click="geralReportByEntity($route.query.entityId)">
              <h3><faIcon type="fal" name="users" />Relatório Completo</h3>
            </a>
            <InputSelect
            v-if="$route.query.entityId != 220 && $route.query.entityId != 214"
              :items="[
                { id: null, name: 'Todos'},
                { id: 2023, name: 2023 },
                { id: 2022, name: 2022 },
                { id: 2021, name: 2021 },
                { id: 2020, name: 2020 },
                { id: 2019, name: 2019 },
              ]"
              textLabel="Ano"
              helpLabel="Ano filtrado no relatório"
              valueAttribute="id"
              textAttribute="name"
              v-model="year"
            ></InputSelect>
          </div>
          <!-- menu ################### -->
          <div class="menu-list">
            <h3 class="ml-3 fw-600">
              <faIcon
                type="fal"
                size="20px"
                name="search-dollar"
                class="mr-2 text-gray"
              />Fluxo financeiro
            </h3>
            <ul class="mt-3">
              <li
                v-for="(item, index) in listMenusWithPermissions"
                :key="index"
              >
                <router-link
                  :to="{
                    name: item.el.route,
                    params: { order: item.index, name: item.el.name },
                  }"
                  v-if="hasAccess(item.el.entityType)"
                >
                  <!-- toggle .active / .done / .disabled -->
                  <em>#{{ item.index }}</em>
                  {{ item.el.name }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="boxapp">
          <router-view
            :pageOptions="pageOptions"
            @changeLevels="changeLevels"
            :entity="entity"
            @pageActive="changePageActive"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script type="plain/text">
import Nav from "@/components/Nav.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Icon from "@/components/Icon.vue";
import EntityService from "@/scripts/services/entity.service.js";
import PageOptions from "@/scripts/models/pageOptions.model";
import { EntityType } from "@/scripts/models/enums/entityType.enum.js";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import ProjectService from "@/scripts/services/project.service.js";
import ImportEmployee from "@/scripts/models/importEmployee.model";
import Entity from "@/scripts/models/entity.model";
import { mapGetters, mapState } from "vuex";
import Permissions from "@/scripts/models/enums/permissions.enum";
import { CashFlowPages } from "@/scripts/models/enums/cashFlowPages.enum";
import ReportService from "@/scripts/services/report.service";

export default {
  data() {
    return {
      breadcrumbLevels: [{ text: "Conciliação e Financeiro" }],
      pageOptions: new PageOptions(),
      campaign: null,
      campaignId: null,
      listCampaign: [],
      permissions: null,
      routes: null,
      pageActive: null,
      entityTypeEnum: null,
      entityService: new EntityService(),
      reportService: new ReportService(),
      entity: new Entity(),
      cashFlowPages: CashFlowPages,
      year: 2021,
      flowList: [
        /*{
          name: "Status Zero",
          route: Routes.app.StatusZero,
          entityType: [EntityType.AUC]
        },*/
        {
          name: "Base de doações",
          route: Routes.app.ResourceFlowList,
          entityType: [EntityType.Company, EntityType.AUC],
        },
        {
          name: "Em conciliação pela empresa",
          route: Routes.app.ConciliationCompany,
          entityType: [EntityType.Company, EntityType.AUC],
        },
        {
          name: "A ser transferido para AUC",
          route: Routes.app.TransferToAUC,
          entityType: [EntityType.Company, EntityType.AUC],
        },
        {
          name: "Conciliado pela empresa",
          route: Routes.app.ConciliedByCompany,
          entityType: [EntityType.Company, EntityType.AUC],
        },
        {
          name: "Em conciliação pela AUC",
          route: Routes.app.OnConciliationAUC,
          entityType: [EntityType.AUC],
        },
        {
          name: "Conciliado AUC",
          route: Routes.app.ConciliedAUC,
          entityType: [EntityType.AUC],
        },
        {
          name: "Pendente de aceite Instituição/Conselho",
          route: Routes.app.PendentAcceptToInstitution,
          entityType: [
            EntityType.Institution,
            EntityType.AUC,
            EntityType.Council,
          ],
        },
        {
          name: "A ser transferido Instituição/Conselho",
          route: Routes.app.PendentTransferToInstitution,
          entityType: [
            EntityType.Institution,
            EntityType.AUC,
            EntityType.Council,
          ],
        },
        {
          name: "Em conciliação Instituição/Conselho",
          route: Routes.app.OnConciliationToInstitution,
          entityType: [
            EntityType.Institution,
            EntityType.AUC,
            EntityType.Council,
          ],
        },
        {
          name: "Conciliado",
          route: Routes.app.Concilied,
          entityType: [
            EntityType.Institution,
            EntityType.AUC,
            EntityType.Council,
          ],
        },
        {
          name: "Rejeitado ",
          route: Routes.app.Declined,
          entityType: [
            EntityType.Institution,
            EntityType.AUC,
            EntityType.Council,
          ],
        },
      ],
    };
  },
  components: {
    Nav,
    Breadcrumb,
    Icon,
  },
  created() {
    this.permissions = Permissions;
    this.entityTypeEnum = EntityType;
    this.routes = Routes;
    this.entityService.findById((data) => {
      this.entity = data;
    }, this.$route.query.entityId);
  },
  //remove footer
  mounted() {
    this.addClass = this.additionalClass;
    this.changeClass();
  },
  computed: {
    ...mapGetters(["hasPermission"]),
    listMenusWithPermissions() {
      if (this.entity.id == 0) return [];
      var list = [];
      var i = 1;
      //if (this.entity.type == EntityType.AUC) i = 0;
      for (let index = 0; index < this.flowList.length; index++) {
        const element = this.flowList[index];
        if (this.hasAccess(element.entityType)) {
          list.push({ index: i, el: element });
          i++;
        }
      }
      return list;
    },
  },
  methods: {
    listCampaigns() {
      this.campaignService
        .listAllCampaignMixAndPublicByCompanyId(this.$route.query.companyId)
        .then(
          function (data) {
            //this.listCampaign = data.filter(x => x.hasEmployees); // filtra por campanhas que tenham funcionarios
            this.listCampaign = data;
            let date = this.listCampaign[0].initialDate;
            this.campaignId = this.listCampaign[0].id;
            let campaignIdInSession = parseInt(
              localStorage.getItem("campaignIdSelected")
            );

            if (this.campaign != null) {
              this.campaign.forEach((element) => {
                if (element.initialDate > date) this.campaignId = element.id;
              });
            }
            if (!isNaN(campaignIdInSession))
              this.campaignId = campaignIdInSession;
          }.bind(this)
        );
    },
    changeClass() {
      this.addClass = "no-footer";
      this.$emit("input", this.addClass);
    },
    changeLevels(levels) {
      this.breadcrumbLevels = levels;
    },
    changePageActive(page) {
      this.pageOptions.CurrentPage = page;
    },
    hasAccess(listEntityType) {
      return listEntityType.includes(this.entity.type);
    },
    geralReportByEntity(entityId) {
      if (entityId == 220 || entityId == 214) {
        this.reportService.iasReport().then((response) => {
          this.openBlob(
            response,
            "Relatorio_IAS",
            "application/excel"
          );
        });
      } else {
        this.reportService
          .geralReportByEntity(entityId, this.year)
          .then((response) => {
            this.openBlob(
              response,
              "Relatorio_Campanhas_Empresa",
              "application/excel"
            );
          });
      }
    },
    openBlob(response, name, type) {
      if (response == null) return;
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: type })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        name + "_" + this.$moment().format("YYYY-MM-DD_H:m") + ".xlsx"
      );
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>