const CashFlowPages = {
	StatusZero: 0,
	ConciliationBase: 1,
	ConciliationByCompany: 2,
	TransferToAUC: 3,
	ConciliedByCompany: 4,
	OnConciliationAUC: 5,
	ConciliedAUC: 6,
	PendentAcceptToInstitution: 7,
	PendentTransferToInstitution: 8,
	OnConciliationInstitution: 9,
	Concilied: 10,
	Declined: 11
};

const CashFlowPagesNameList = [
	{ id: CashFlowPages.ConciliationBase, name: "1 - Base de Doações" },
	{ id: CashFlowPages.ConciliationByCompany, name: "2 - Em conciliação pela empresa" },
	{ id: CashFlowPages.TransferToAUC, name: "3 - A ser tranferido para AUC" },
	{ id: CashFlowPages.ConciliedByCompany, name: "4 - Conciliação pela empresa" },
	{ id: CashFlowPages.OnConciliationAUC, name: "5 - Em conciliação pela AUC" },
	{ id: CashFlowPages.ConciliedAUC, name: "6 - Conciliado AUC" },
	{ id: CashFlowPages.PendentAcceptToInstitution, name: "7 - Pendente de aceite Instituição/Conselho" },
	{ id: CashFlowPages.PendentTransferToInstitution, name: "8 - A ser transferido Instituição/Conselho" },
	{ id: CashFlowPages.OnConciliationInstitution, name: "9 - Em conciliação Instituição/Conselho" },
	{ id: CashFlowPages.Concilied, name: "10 - Conciliado" },
	{ id: CashFlowPages.Declined, name: "11 - Rejeitado" }
  ]

 const CashFlowPagesForStatusZeroNameList = [
	{ id: CashFlowPages.TransferToAUC, name: "3 - A ser tranferido para AUC" },
	{ id: CashFlowPages.ConciliedByCompany, name: "4 - Conciliação pela empresa" },
	{ id: CashFlowPages.OnConciliationAUC, name: "5 - Em conciliação pela AUC" },
	{ id: CashFlowPages.ConciliedAUC, name: "6 - Conciliado AUC" },
	{ id: CashFlowPages.PendentAcceptToInstitution, name: "7 - Pendente de aceite Instituição/Conselho" },
	{ id: CashFlowPages.PendentTransferToInstitution, name: "8 - A ser transferido Instituição/Conselho" },
	{ id: CashFlowPages.OnConciliationInstitution, name: "9 - Em conciliação Instituição/Conselho" },
	{ id: CashFlowPages.Concilied, name: "10 - Conciliado" }
  ]

export { CashFlowPages, CashFlowPagesNameList, CashFlowPagesForStatusZeroNameList };