import Person from "@/scripts/models/person.model";

export default class ImportEmployee {

	constructor() {
		this.id = 0;
        this.userId = 0;
        this.CampaignId = 0;
        this.registers = 0;
        this.registersSuccess = 0;
        this.registersError = 0;
        this.registersDuplicated = 0;
        this.registersRemoved = 0;
        this.emailGroupChanged = 0;
        this.newRegisters = 0;
        this.uploadDate = "";
        this.fieldRequired = "";
        this.status = "";
        this.user = new Person();
	}

}